import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BrandNew from "../components/brandNew"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const featuredImage = post.frontmatter.featuredImage

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={featuredImage.childImageSharp.fluid.fluid}
        />
        <div className="px-4 py-16 sm:max-w-full md:max-w-full md:px-24 lg:px-8 lg:py-20 bg-gray-900">
          <div className="mx-auto sm:text-center lg:max-w-2xl">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <BrandNew date={post.frontmatter.date} />
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-32 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                  >
                    <defs>
                      <pattern
                        id="5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95"
                        x="0"
                        y="0"
                        width=".135"
                        height=".30"
                      >
                        <circle cx="1" cy="1" r=".7" />
                      </pattern>
                    </defs>
                    <rect
                      fill="url(#5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95)"
                      width="52"
                      height="24"
                    />
                  </svg>

                  <span className="relative">{post.frontmatter.title}</span>
                </span>{" "}
                {/* {post.frontmatter.description || post.excerpt} */}
              </h2>
              <div className="mb-4 transition-shadow duration-300 hover:shadow-xl lg:mb-6">
                {featuredImage && (
                  <Img
                    fluid={featuredImage.childImageSharp.fluid}
                    className="object-cover w-full h-56 rounded shadow-lg sm:h-64 md:h-80 lg:h-96"
                  />
                )}
              </div>
              <article className="markdown prose prose-red text-white sm:text-center md:text-left md:text-xl my-4 md:-mx-16 ">
                <MDXRenderer>{post.body}</MDXRenderer>
              </article>
              <nav>
                <ul
                  className="flex flex-wrap space-between mb-8"
                  // style={{
                  //   display: `flex`,
                  //   flexWrap: `wrap`,
                  //   justifyContent: `space-between`,
                  //   listStyle: `none`,
                  //   padding: 0,
                  // }}
                >
                  <li>
                    {previous && (
                      <Link
                        className="text-white text-xl p-4"
                        to={previous.fields.slug}
                        rel="prev"
                      >
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link
                        className="text-white text-xl p-4"
                        to={next.fields.slug}
                        rel="next"
                      >
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 524) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
        }
      }
    }
  }
`
