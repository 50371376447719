import React from "react"

const BrandNew = date => {
  const twoWeeksAgo = new Date().getDate() - 14

  if (date < twoWeeksAgo) {
    return (
      <>
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-red-500">
            Brand new
          </p>
        </div>
      </>
    )
  } else {
    return null
  }
}

export default BrandNew
